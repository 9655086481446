import React from 'react'

import {Link} from 'gatsby'
import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import {responsive} from '../../utils/responsive'
import English_Footer from "../../components/english_footer";

const AboutPage = () => {
    return (<Layout languageSwitchLink="/about">
        <SEO title="About Us" />
        <Banner class_suffix="deep" />
        <div className="about-container">
            <div className="about-content">
                <h3>Who are we?</h3>
                <p>
                    PolyCyber is a technical society at Polytechnique Montréal. Since 2013, it has been working to develop the interest and skills of our student community in cybersecurity.
                </p>
                <h3>Our Missions</h3>
                <ul>
                    <li>Promote knowledge transfer</li>
                    <li>Represent Polytechnique Montréal in cybersecurity competitions</li>
                    <li>Build relationships with the industry</li>
                    <li>Raise awareness in our community about cybersecurity issues</li>
                </ul>
                <h3>Our Activities</h3>
                <p>
                    Throughout the year, PolyCyber organizes many activities such as technical workshops, conferences, and competitions. In addition, we frequently participate in Capture the Flag contests, where participants must solve multiple challenges and find a string of characters (called a flag) that proves the challenge has been solved. Visit our blog to see the write-ups of the competitions we’ve participated in on our <Link to="/blog">blog</Link>.
                </p>
                <h3>Our Support</h3>
                <p>
                    Our activities are made possible thanks to the great support of our sponsors and partners. Learn more about what our sponsors do and the profiles they are looking for on the <Link to="/partners">Partners</Link> page.
                </p>
                <a href="/en/join">
                    <button className="signup-button"> 
                    Join Us!
                    </button>
                </a>
            </div>
        </div>

        <English_Footer></English_Footer>
    </Layout>)
}
export default AboutPage

